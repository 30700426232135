import * as React from 'react';
import type { DialogWithContentBase } from '../dialog-with-content/dialog-with-content';
import DialogWithContent from '../dialog-with-content/dialog-with-content';

type AlertDialog = DialogWithContentBase & {
  label?: string;
  ariaLabel?: string;
  onOpen?: () => void;
};

const AlertDialog: React.FC<AlertDialog> = function ({ label, ariaLabel, onOpen, ...rest }) {
  const [open, setOpen] = React.useState(false);
  const onClick = () => {
    setOpen(true);
    onOpen && onOpen();
  };
  return (
    <>
      <button className="underline" onClick={onClick} type="button">
        {label}
      </button>
      <DialogWithContent
        isOpen={open}
        isAlert
        ariaLabel={ariaLabel}
        onClose={() => setOpen(!open)}
        alertMessages={rest.alertMessages}
      />
    </>
  );
};

export default AlertDialog;
