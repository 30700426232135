import ReactMarkdown from 'markdown-to-jsx';
import cx from 'classnames';

import { isBrowser } from '@dx-ui/utilities-is-browser';

const targetOpensInNewWindow = (href: string) => (href ? href.endsWith('target_blank') : false);

type LinkRendererProps = {
  href: string;
  children: React.ReactNode[];
};

const baseClass = 'font-headline text-brand';

const MarkdownLinkRenderer = function (props: LinkRendererProps) {
  let { href } = props;
  const className = 'text-primary hover:text-primary-alt underline';

  if (targetOpensInNewWindow(href)) {
    href = href.replace('/target_blank', '');
    return (
      <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
        {props.children}
      </a>
    );
  }
  if (href.endsWith('_backToTop')) {
    href = href.replace('_backToTop', '');
    return (
      <>
        <a href={href} className={cx(className)}>
          {props.children}
        </a>
        <div />
      </>
    );
  }
  if (href.endsWith('Join_US')) {
    href = href.replace('Join_US', '');
    return (
      <a href={href} className={cx(className, 'join-us-link')}>
        {props.children}
      </a>
    );
  }

  if (href === 'teconsent') {
    if (isBrowser && window?.location?.href?.includes('?trustarc')) {
      return (
        <a id={href} href={href} className={className}>
          {}
        </a>
      );
    }
    return (
      <a id={href} href={href} className={className}>
        {props.children}
      </a>
    );
  }
  return (
    <a href={href} className={className}>
      {props.children}
    </a>
  );
};

type THeaderRenderer = {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'table' | 'th' | 'td';
  children: React.ReactNode;
};

const TagRenderer = function ({ tag: Tag, children, ...props }: THeaderRenderer) {
  return <Tag {...props}>{children}</Tag>;
};

export const CustomMarkdown = function ({ children }: { children: string }) {
  return (
    <ReactMarkdown
      options={{
        overrides: {
          a: {
            component: MarkdownLinkRenderer,
          },
          h1: {
            component: TagRenderer,
            props: {
              tag: 'h1',
              className: cx(baseClass, 'text-2xl lg:mb-2 lg:text-4xl'),
            },
          },
          h2: {
            component: TagRenderer,
            props: {
              tag: 'h2',
              className: cx(baseClass, 'text-xl lg:mb-1 lg:text-2xl'),
            },
          },
          h3: {
            component: TagRenderer,
            props: {
              tag: 'h3',
              className: cx(baseClass, 'text-lg lg:mb-1 lg:text-xl'),
            },
          },
          table: {
            component: TagRenderer,
            props: {
              tag: 'table',
              // className: 'table-auto border-collapse border border-border-alt',
              className: 'table-auto -ml-2',
            },
          },
          th: {
            component: TagRenderer,
            props: {
              tag: 'th',
              // className: 'border border-border-alt px-2 py-1',
              className: 'px-2 py-1',
            },
          },
          td: {
            component: TagRenderer,
            props: {
              tag: 'td',
              // className: 'border border-border-alt px-2 py-1',
              className: 'px-2 py-1',
            },
          },
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
