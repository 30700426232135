import { useGetHotelAlertsQuery } from '@dx-ui/queries-dx-property-ui';
import { WrappedPropertyAlerts } from './property-alerts';

export type PropertyAlerts = {
  ctyhocn: string;
};

const PropertyAlerts = ({ ctyhocn }: PropertyAlerts) => {
  const { data, isError, isLoading } = useGetHotelAlertsQuery({
    ctyhocn,
  });
  const messages = data?.hotelPageTemplate?.hotel?.alerts;

  if (messages && !isError && !isLoading) {
    return <WrappedPropertyAlerts messages={messages} />;
  }

  return null;
};

export default PropertyAlerts;
